import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import TwoColumnImgTxt from '../components/TwoColumnImgTxt';

const nsBase = 'page';
const ns = `${ nsBase }-platform`;

const PlatformPage = ({ data }) => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	const {
		platformJson: {
			hero,
			hideSectionsImage,
			sections
		}
	} = data;

	return (
		<div className={rootClassnames}>
			<SEO title={'Platform'} page={'platform'} />
			<Hero
				variant={'purple'}
				heading={hero.heading}
				pageTitle={hero.pageTitle}
				anchors={hero.anchors}
			/>
			<div className={`${ ns }__sections`}>
				{sections.length && sections.map((section, i) => {
					// hero anchors should be in same order as their
					// corresponding section for anchor linking
					let id = hero.anchors[i].anchor || '';
					id = id.replace(/#/, '');

					const count = i + 1;

					return (
						<TwoColumnImgTxt
							id={id}
							key={uniqueId('envoy')}
							hideSectionsImage={hideSectionsImage}
							count={count}
							{...section} // eslint-disable-line
						/>
					);
				})}
			</div>
		</div>
	);
};

export default PlatformPage;

export const query = graphql`
  query GET_PAGE_PLATFORM {
		platformJson {
			hero {
				heading,
				pageTitle,
				anchors {
					text,
					anchor
				}
			}
			hideSectionsImage
			sections {
				heading,
				description,
				image {
					alt_text,
					main_image,
					laptop_image
				}
			}
		}
	}
`;
