import React from 'react';
import classNames from 'classnames';

import Button from '../Button';
import Image from '../Image';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-two-column-img-txt`;

const formatCount = (num) => {
	return num < 10 ? `0${ num }` : num;
};

const TwoColumnImgTxt = (props) => {
	const {
		hideSectionsImage,
		id,
		count,
		heading,
		description,
		cta,
		image
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`image-hidden`]: hideSectionsImage
	});

	return (
		<div id={id} className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__col`}>
					{!hideSectionsImage && (
						<Reveal>
							<Image {...image} />
						</Reveal>
					)}
				</div>
				<div className={`${ ns }__col`}>
					<Reveal>
						<div className={`${ ns }__text-content`}>
							{count && (
								<span className={`h2`}>{formatCount(count)}</span>
							)}
							{heading && (
								<h2>{heading}</h2>
							)}
							{description && (
								<p>{description}</p>
							)}
							{cta && cta.label && cta.url && (
								<Button href={cta.url} variant={'white'}>{cta.label}</Button>
							)}
						</div>
					</Reveal>
				</div>
			</div>
		</div>
	);
};

export default TwoColumnImgTxt;
